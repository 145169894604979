import React, { useEffect, useState } from 'react';
import { List as IList } from 'immutable';
import { useTranslation } from 'react-i18next';
import { list } from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ForYouArticle from './ForYouArticle';
import NoArticles from '../NoArticles';

import styles from './forYou.module.scss';

const ForYou = (props) => {
  const { articles, category, articleView } = props;
  const { t } = useTranslation();
  const [showArticles, setShowArticles] = useState(IList());
  const [hideShowMoreButton, setHideShowMoreButton] = useState(false);

  useEffect(() => {
    props.actions.getForYouArticles({ category });
  }, [category]);

  useEffect(() => {
    setShowArticles(articles.slice(0, 3));
  }, [articles]);

  useEffect(() => {
    if (showArticles.size === articles.size) {
      setHideShowMoreButton(true);
    } else {
      setHideShowMoreButton(false);
    }
  }, [showArticles, articles]);

  const showMore = () => {
    const currentArticles = showArticles.size;
    if (currentArticles !== articles.size) {
      setShowArticles(
        showArticles.merge(articles.slice(currentArticles, currentArticles + 3))
      );
    }
  };

  if (articles.size === 0) {
    return <NoArticles noArticles />;
  }

  return (
    <div
      className={classnames(
        styles.forYou,
        articleView && styles.forYouArticleView
      )}
    >
      <div>
        <h1 className={styles.forYouTitle}>{t('news forYou title')}</h1>
        <div className={styles.slider} />
        <div className={styles.sliderTag} />
      </div>
      <div className={styles.articlesContainer}>
        {showArticles.map((article) => (
          <ForYouArticle key={article.get('slug')} article={article} />
        ))}
      </div>
      {hideShowMoreButton ? null : (
        <button
          type="button"
          onClick={showMore}
          className={styles.showMoreButton}
        >
          <span>Show more</span>
          <span className={styles.arrow}>»</span>
        </button>
      )}
      <a href="https://www.bhtelecom.ba/usluge-za-privatne-korisnike/2024/05/netflat-u-pola-cijene/">
        <img
          src="https://www.bhtelecom.ba/wp-content/uploads/2024/05/netFlat-akcija-350x262.png"
          alt="banner"
          style={{ width: '100%' }}
        />
      </a>
    </div>
  );
};

const { shape, string, func, bool } = PropTypes;

ForYou.propTypes = {
  articleView: bool,
  articles: list.isRequired,
  category: string.isRequired,
  actions: shape({
    getForYouArticles: func.isRequired,
  }).isRequired,
};

ForYou.defaultProps = {
  articleView: false,
};

export default ForYou;
